hmtl,
body {
  overflow: hidden;
  font-family: "Sanchez", serif;
}
.main {
  height: 100vh;
  width: 100vw;
  background-color: #8b9899;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.background {
  background-color: #aba9bf;
  transform: skew(0deg, -10deg);
  z-index: 5;
  height: 100%;
  position: absolute;
  top: -45%;
  width: 100%;
}
.content {
  z-index: 10;
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headline {
  position: relative;
  /* color: #e0e0e0 !important; */
  font-size: 5rem !important;
}

/* For nicer look on small phones */
@media screen and (max-width: 600px) {
  .headline {
    font-size: 3rem !important;
  }
}

.text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
  text-align: center;
}

.headline .headline-text {
  display: inline-block;
  line-height: 1em;
}

.headline .headline-underline {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 5px;
  width: 100%;
  /* background-color: #e0e0e0; */
  background-color: #363636;
  transform-origin: 0.5 0;
  bottom: 0;
  border-radius: 25px;
}

.link {
  color: #e0e0e0;
}
